.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.ReatTableWrap table thead tr th {
    background-color: #d9edf7;
}

.react-datepicker__input-container > input:focus {
    outline-color: #2684ff !important;
    border: 0;
}

.react-datepicker__input-container > input {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: content-box;
    height: 1.4375em;
    margin: 0;
    display: block;
    width: 95%;
    animation-duration: 10ms;
    padding: 8.5px 10px;
}

.react-datepicker__close-icon:after {
    color: #ccc !important;
    font-size: 14px !important;
    content: '\2716' !important;
    background: none !important;
}

#react_table_search {
    height: 10px;
}

#Country_Select .dropdown-content {
    top: -310px !important;
    padding-top: 0 !important;
    border: 2px solid #eee !important;
    border-radius: 5px !important;
}
